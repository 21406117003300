export default [
  {
    header: '网站管理',
  },
  {
    title: '官网信息',
    route: 'website-web',
  },
  {
    title: '新闻动态',
    icon: 'AirplayIcon',
    children: [
      {
        title: '新闻分类',
        route: 'news-cate',
      },
      {
        title: '新闻管理',
        route: 'news-list',
      },
    ]
  },
  {
    title: '官网业务',
    icon: 'AirplayIcon',
    children: [
      {
        title: '业务分类',
        route: 'zixun-cate',
      },
      {
        title: '业务管理',
        route: 'zixun-list',
      },
    ]
  },
  {
    title: '客户留言',
    route: 'feedback-list',
  },
  {
    header: '推广服务',
  },
  {
    title: '员工管理',
    route: 'staff-list',
  },
  {
    title: '名片管理',
    route: 'callcard-list',
  },
  {
    title: '项目管理',
    icon: 'AirplayIcon',
    children: [
      {
        title: '项目列表',
        route: 'project-list',
      },
      {
        title: '营商服务',
        route: 'service-list',
      },
      {
        title: '项目产品',
        route: 'product-list',
      },
      {
        title: '项目政策',
        route: 'policy-list',
      },
      {
        title: '图册管理',
        route: 'image-list',
      },
      {
        title: '视频管理',
        route: 'video-list',
      },
    ]
  },
  {
    title: '话术管理',
    icon: 'AirplayIcon',
    children: [
      {
        title: '话术分类',
        route: 'talks-cate',
      },
      {
        title: '话术列表',
        route: 'talks-list',
      },
    ]
  },
  {
    header: '系统管理',
  },
  {
    title: '组织架构',
    route: 'system-depart',
  },
  {
    icon: 'FileIcon',
    title: '附件管理',
    route: 'system-files',
  },
  {
    title: '权限管理',
    route: 'beilun-base4',
    children: [
      {
        title: '权限规则',
        route: 'beilun-base7',
      },
      {
        title: '角色管理',
        route: 'beilun-base8',
      },
      {
        title: '账号管理',
        route: 'account-list',
      },
    ],
  },
  {
    title: '系统配置',
    route: 'config-design',
  },
  {
    title: '组合数据',
    route: 'beilun-base5',
  },

]
